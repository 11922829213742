import React from "react";
import "./_ContactUsStyle.css";
 import PhoneIcon from "../../img/whatsapp.png";
import EmailIcon from "../../img/email.png";
import InstaIcon from "../../img/instagram.png";
 
export default function ContactUs() {
 
  return (
    <>
      <section className="cotactSec" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-6 p-5" dir="rtl">
              <h3 dir="rtl">تواصل معانا</h3>
              <div className="titleLine m-2"></div>
              <h4 className="mb-4"> للاستفسارات و الحجز</h4>
              <div className="phone d-flex align-end mt-5">
                <div className="icons">
                  <img src={PhoneIcon} alt="" />
                </div>
                <h6 className="me-2 mt-1" dir="ltr">
                  4990 301 053
                </h6>
              </div>
              <div className=" d-flex align-end mt-1 ">
                <div className="icons C">
                  <img src={EmailIcon} alt="" />
                </div>
                <h6 className="me-2 mt-1"> Shinybubbles.Jeddah@gmail.com</h6>
              </div>
              <div className=" d-flex align-end mt-1 ">
                <div className="icons C">
                  <img src={InstaIcon} alt="" />
                </div>
                <span className="mt-1 mx-2">
                  <a
                    href="=https://instagram.com/shinybubbles.jeddah?igshid=MWI4MTIyMDE"
                     target="_blank"
                     className="text-decoration-none text-dark" 
                  >
                    Shinybubbles
                  </a>
                </span>
              </div>
            </div>
            <div className="col-md-5  d-flex justify-content-center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d475323.9792524807!2d39.2111492!3d21.45012295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d01fb1137e59%3A0xe059579737b118db!2sJeddah!5e0!3m2!1sen!2ssa!4v1678942544814!5m2!1sen!2ssa"
                width="600"
                height="450"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
