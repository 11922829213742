import React from "react";
import "./_AboutStyle.css";
// import aboutImg from "../../img/About.png";
// import btnO from "../../img/btnO.png";
export default function AboutUs() {
  return (
    <>
      <section className="aboutus" id="about">
        <div className="container p-5 " dir="rtl">
          <div className="row d-flex  align-items-center  m-auto ">
        
            {/* <div className="col-md-6 p-3 " dir="rtl">
              <div className="group">
                <div className="d-flex align-items-center">
                  <div className="circle"></div>
                  <h3 className="me-2">جوده فائقه</h3>
                </div>
                <div className="d-flex ">
                  <div className="lineH me-1 ms-2"></div>
                  <p className="text-muted">
                    لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج
                    أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات دولار
                  </p>
                </div>
              </div>
              <div className="group">
                <div className="d-flex align-items-center">
                  <div className="circle"></div>
                  <h3 className="me-2">جوده فائقه</h3>
                </div>
                <div className="d-flex ">
                  <div className="lineH me-1 ms-2"></div>
                  <p className="text-muted">
                    لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج
                    أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات دولار
                  </p>
                </div>
              </div>
              <div className="group">
                <div className="d-flex align-items-center">
                  <div className="circle"></div>
                  <h3 className="me-2">جوده فائقه</h3>
                </div>
                <div className="d-flex ">
                  <div className="lineH me-1 ms-2"></div>
                  <p className="text-muted">
                    لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج
                    أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات دولار
                  </p>
                </div>
              </div>
            </div> */}

            <div className="col-md-6 m-auto " dir="rtl">
              <div className="text-center">
                <h1>من نحن</h1>
                <div className="titleLine  mt-3 mb-3 m-auto"></div>
              </div>
              <p dir="rtl" className="text-muted">
                خدمة غسيل سيارات متنقلة بالبخار و باستخدام أفضل أدوات التنظيف . يسعدنا خدمتكم في اي مكان داخل مدينة جدة.
              </p>

              <div className=" text-center  btn w-100 btnO">احجز الان</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
